import React from 'react';

const IconGitLab = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 586 559"
    strokeWidth="20"
    strokeLinecap="round"
    strokeLinejoin="round"
    role="img"
    stroke="currentColor">
    <title>GitLab</title>
    <path
      strokeWidth="15"
      d="m577.78916,309.00379l-30.50885,-86.97646c0.01352,0.03912 0.01859,0.0798 0.03212,0.11892c-0.01521,-0.04538 
      -0.02366,-0.09075 -0.03719,-0.13613c-0.00169,-0.00313 -0.00169,-0.00469 -0.00169,-0.00782c0,-0.00156 0,-0.00313 
      0,-0.00313l-60.56468,-172.60102c-3.31306,-9.09242 -12.42903,-15.19471 -22.88037,-15.11961c-10.48176,0.05007 -19.34757,6.03501 
      -22.58963,15.2526l-57.48827,163.83093l-181.39485,0l-57.59645,-163.84188c-3.23868,-9.20977 -12.10617,-15.19315 -22.58625,
      -15.24322c-0.04226,0 -0.08283,0 -0.12678,0c-10.31949,0 -19.45406,6.06474 -22.80261,15.25886l-60.39903,172.44611l-0.00845,
      0.02191c0,0.00156 0,0.00313 0,0.00469c-0.01521,0.04381 -0.02197,0.08762 -0.03719,0.13143c0.01352,-0.03912 0.01859,-0.0798 
      0.03212,-0.11892l-30.62041,86.98271c-4.60616,13.13088 0.39216,27.40086 12.44086,35.51378l264.39534,177.82396c0.03888,0.0266 
      0.08283,0.04225 0.1217,0.06885c0.11325,0.07511 0.22312,0.15177 0.33807,0.22219c-0.10818,-0.06728 -0.2096,-0.14082 -0.31609,
      -0.20967c0,0 0,-0.00156 -0.00169,-0.00156c0.0169,0.01252 0.03719,0.02034 0.05578,0.03129c0.01521,0.00939 0.03043,0.01565 
      0.04564,0.02504c0.00169,0.00156 0.00338,0.00313 0.00676,0.00469c0.40906,0.26287 0.83333,0.50383 1.27282,0.72445c0.06085,
      0.03129 0.11494,0.07041 0.17579,0.10014c0.00169,0 0.00169,0.00156 0.00338,0.00156c0.03719,0.01721 0.07099,0.03912 0.10818,
      0.05633c0.02874,0.01252 0.05916,0.02034 0.08621,0.03286c0.02028,0.00939 0.04226,0.01565 0.06254,0.02347c0.04902,0.02191 
      0.10311,0.03599 0.15213,0.05946c0.22989,0.1017 0.4716,0.18463 0.70825,0.27382c0.22143,0.08449 0.43611,0.18307 0.66261,0.25661c0.01014,
      0.00313 0.01859,0.00782 0.02874,0.01095c0.03719,0.01252 0.07099,0.02973 0.10987,0.04225c0.04733,0.01565 0.09297,0.03286 0.1403,
      0.04694c0.01859,0.00469 0.03719,0.00782 0.05578,0.01252c0.05916,0.01721 0.12339,0.02504 0.18256,0.04068c0.02197,0.00626 0.04733,
      0.00939 0.07099,0.01565c0.31778,0.08919 0.6474,0.15334 0.97532,0.22062c0.12847,0.0266 0.25186,0.06415 0.38202,0.08606c0.01859,
      0.00313 0.0355,0.00939 0.05578,0.01252c0.04226,0.00782 0.08114,0.02191 0.12508,0.02816c0.0693,0.01095 0.13692,0.03129 0.20791,
      0.04068c0.05578,0.00782 0.11325,0.00469 0.16903,0.01252c0.01014,0.00156 0.01859,0 0.02874,0.00156c0.00338,0 0.00507,0 0.00845,0c0.62373,
      0.08293 1.25592,0.14082 1.89994,0.14082c0.00338,0 0.00676,0 0.01183,0l0,0c0.00169,0 0.00338,0 0.00338,0c0,0 0.00169,0 0.00169,0c0.00169,
      0 0.00338,0 0.00507,0c0.64571,0 1.27789,-0.05789 1.90332,-0.14082c0.00169,0 0.00507,0 0.00676,0c0.01014,-0.00156 0.02028,0 0.03043,
      -0.00156c0.05578,-0.00782 0.11494,-0.00469 0.17072,-0.01252c0.07099,-0.01095 0.13861,-0.02973 0.2096,-0.04068c0.04226,-0.00626 0.08114,
      -0.02034 0.12339,-0.02816c0.01859,-0.00313 0.0355,-0.00939 0.05409,-0.01252c0.13185,-0.02347 0.25862,-0.06102 0.39047,-0.08762c0.32285,
      -0.06572 0.6474,-0.12987 0.96349,-0.21749c0.02197,-0.00626 0.04395,-0.00782 0.06592,-0.01408c0.06254,-0.01721 0.12678,-0.02504 0.18932,
      -0.04225c0.01859,-0.00626 0.03888,-0.00782 0.05747,-0.01252c0.04902,-0.01408 0.09635,-0.03286 0.14368,-0.04851c0.03719,-0.01252 0.07099,
      -0.02973 0.10818,-0.04225c0.01014,-0.00313 0.01859,-0.00782 0.02874,-0.01095c0.24003,-0.07823 0.46653,-0.1815 0.70149,-0.27069c0.21805,
      -0.08449 0.44118,-0.1596 0.65416,-0.25348c0.0524,-0.02347 0.10818,-0.03755 0.15889,-0.06102c0.02028,-0.00939 0.04395,-0.01565 0.06423,
      -0.02504c0.02874,-0.01252 0.05916,-0.02034 0.0879,-0.03442c0.03888,-0.01878 0.07607,-0.04068 0.11325,-0.05789c0,0 0.00169,0 0.00169,
      -0.00156c0.06254,-0.02973 0.11832,-0.07198 0.18087,-0.10327c0.43611,-0.21906 0.85869,-0.45845 1.26606,-0.71976c0.03212,-0.02034 
      0.0693,-0.03599 0.10311,-0.05789c0.00845,-0.00626 0.01859,-0.00939 0.02705,-0.01565c0.03888,-0.0266 0.08452,-0.04381 0.12339,-0.07041l264.43591,
      -177.82709c12.04194,-8.10823 17.04026,-22.3782 12.4341,-35.50909zm-113.79331,-243.43613l51.85946,147.79441l-103.71892,0l51.85946,
      -147.79441zm42.77729,172.78565l-21.1613,25.0992l-156.07533,185.11385l73.82873,-210.21305l103.4079,0zm-226.6282,277.7404c0,0 0,0 0,
      0c0.01352,0.03755 0.0355,0.07198 0.04902,0.11109c-0.01352,-0.03912 -0.0355,-0.07354 -0.04902,-0.11109zm-23.6782,-67.51483l-177.14028,
      -210.22557l0,0l103.41466,0l73.72562,210.22557zm-134.45765,-383.01279l51.95581,147.79598l-103.71892,0l51.76311,-147.79598zm-85.4819,
      258.73724c-2.56255,-1.72585 -3.62408,-4.77231 -2.64199,-7.56997l22.76204,-64.66084l166.78529,197.93649l-186.90534,-125.70568zm248.05488,
      197.70804c-0.0524,-0.03912 -0.10311,-0.08136 -0.15382,-0.12205c-0.01014,-0.00782 -0.02028,-0.01878 -0.03212,-0.0266c-0.10142,-0.07823 
      -0.20115,-0.15803 -0.29919,-0.2394c-0.1927,-0.1549 -0.38202,-0.31294 -0.56288,-0.4788c0.01521,0.01252 0.03212,0.02347 0.04733,0.03599c0.02028,
      0.01721 0.04226,0.03129 0.06254,0.04851c0.38709,0.34267 0.79446,0.66499 1.22042,0.96228c0.00507,0.00313 0.00845,0.00782 0.01352,0.01095c0.02028,
      0.01408 0.03719,0.03129 0.05747,0.04694c-0.11663,-0.07823 -0.23834,-0.15334 -0.35328,-0.23783zm8.40941,-50.22187l-43.38243,-123.70287l-38.48215,
      -109.73332l163.84918,0l-81.9846,233.43619zm8.87933,49.83383c-0.10142,0.08136 -0.19946,0.16273 -0.30257,0.24096c-0.01183,0.00939 -0.02366,0.02034 
      -0.0355,0.02973c-0.0524,0.03912 -0.10142,0.08136 -0.15213,0.12048c-0.11156,0.08293 -0.23327,0.15803 -0.3499,0.23783c0.02028,-0.01408 0.03719,
      -0.03286 0.05916,-0.04538c0.00338,-0.00313 0.00676,-0.00626 0.01014,-0.00939c0.42596,-0.29729 0.83164,-0.61649 1.21535,-0.95916c0.01521,
      -0.01408 0.03381,-0.02504 0.04902,-0.03755c0.02028,-0.01721 0.04226,-0.03129 0.06085,-0.04851c-0.17918,0.16116 -0.3668,0.31763 -0.55443,
      0.47097zm247.60863,-197.32469l-186.8783,125.67125l166.84107,-197.88172l22.67076,64.63267c0.98377,2.81018 -0.07607,5.85664 -2.63354,7.5778z"
    />
  </svg>
);

export default IconGitLab;
