import React from 'react';

const IconStackOverflow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 586 559"
    strokeLinejoin="round"
    strokeLinecap="round"
    strokeWidth="2"
    stroke="currentColor"
    fill="none">
    <title>Stack Overflow</title>
    <polygon
      fill="currentColor"
      points="463.9756622314453,357.90374755859375 
      516.4514007568359,357.90374755859375 516.4514007568359,555.1185302734375 44.16999816894531,555.1185302734375 44.16999816894531,
      357.90374755859375 96.64569854736328,357.90374755859375 96.64569854736328,505.8148193359375 463.9756622314453,505.8148193359375 "
    />
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="m153.83416,343.24709l257.72729,50.89486l10.83385,-48.40726l-257.72729,-50.91727l-10.83385,48.42967zm34.10444,-115.94884l238.74539,
      104.47004l22.24016,-44.86188l-238.75016,-104.47004l-22.23539,44.86188zm66.06215,-110.05032l202.40358,158.35898l33.70849,-38.03108l-202.40358,
      -158.3545l-33.70849,38.02659l0,0.00001zm130.6502,-117.05593l-42.2668,29.53739l157.17906,198.55942l42.2668,-29.53291l-157.17906,-198.5639zm-235.52954,
      456.31912l262.37854,0l0,-49.30369l-262.37854,0l0,49.30369z"
    />
  </svg>
);

export default IconStackOverflow;
